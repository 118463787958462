import request from '@/utils/request'

const rolesApi = {
  // get roles info
  roles: '/api/v1/rest/roles',
  countAuthByRoleIdRole: '/api/v1/rest/roles/search/countAuthByRoleId',
  grantAllToAdminRole: '/api/v1/rest/roles/search/grantAllToAdmin',
  grantAuthRole: '/api/v1/rest/roles/search/grantAuth'
}

export function roles(params) {
  let url = rolesApi.roles
  url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}

export function addRoles(data) {
  return request({
    url: rolesApi.roles,
    method: 'post',
    data
  })
}

export function getRoles(id) {
  return request({
    url: rolesApi.roles + `/${id}`,
    method: 'get'
  })
}

export function putRoles(id, data) {
  return request({
    url: rolesApi.roles + `/${id}`,
    method: 'put',
    data
  })
}

export function patchRoles(id, data) {
  return request({
    url: rolesApi.roles + `/${id}`,
    method: 'patch',
    data
  })
}

export function delRoles(id) {
  return request({
    url: rolesApi.roles + `/${id}`,
    method: 'delete'
  })
}

export function getRolesAuths(id) {
  const url = rolesApi.roles + `/${id}` + '/auths'
  console.log(url);
  return request({
    url,
    method: 'get'
  })
}

export function putRolesAuths(id, data) {
  return request({
    url: rolesApi.roles + `/${id}` + '/auths',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function patchRolesAuths(id, data) {
  return request({
    url: rolesApi.roles + `/${id}` + '/auths',
    method: 'patch',
    data
  })
}

export function delRolesAuths(id) {
  return request({
    url: rolesApi.roles + `/${id}` + '/auths',
    method: 'delete'
  })
}

export function getRolesIdAuths(id, authId) {
  return request({
    url: rolesApi.roles + `/${id}` + '/auths' + `/${authId}`,
    method: 'get'
  })
}

export function delRolesIdAuths(id, authId) {
  return request({
    url: rolesApi.roles + `/${id}` + '/auths' + `/${authId}`,
    method: 'delete'
  })
}

export function deleteByIdsUser(params) {
  let url = rolesApi.deleteByIdsUser
  url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}

export function findByUsernameUser(params) {
  let url = rolesApi.findByUsernameUser
  url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}

export function findByWxOpenIdUser(params) {
  let url = rolesApi.findByWxOpenIdUser
  url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}
