<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handle(null, 'add')">新建角色</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="loadData"
        :alert="true"
        :totalCount="totalCount"
        :scroll="defaultScroll"
        showPagination="auto"
      >
        <span class="myCol" slot="name" slot-scope="text">
          {{ text }}
        </span>
        <span slot="sys" slot-scope="text">
          {{ text ? '是' : '否' }}
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="auth" slot-scope="auth, record">
          <a-tag color="blue" class="myPointer" @click="handle(record, 'auth')">配置</a-tag>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handle(record, 'edit')">编辑</a>
            <!-- <a-divider type="vertical"></a-divider>
            <a @click="handle(record, 'del')">删除</a> -->
          </template>
        </span>
      </s-table>

      <a-modal
        title="刪除"
        :visible="del.visible"
        :confirm-loading="del.confirmLoading"
        @ok="handleDeleteOk"
        @cancel="handleCancel('del')"
      >
        <p>{{ del.ModalText }}</p>
      </a-modal>

      <a-drawer :title="title" placement="right" width="800" :visible="authVisible" @close="handleCancel('auth')">
        <a-spin :spinning="authLoading">
          <a-tree
            class="myTree"
            v-model="checkedKeys"
            :defaultExpandAll="true"
            :checkStrictly="true"
            :show-line="true"
            checkable
            :tree-data="fetchAuthTree"
            :replace-fields="replaceFields"
            @check="onCheck"
          />
        </a-spin>
        <div class="footerHolder"></div>
        <div
          :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px'
          }"
        >
          <a-button style="marginRight: 8px" @click="handleCancel('auth')">
            取消
          </a-button>
          <a-button type="primary" :loading="saveLoading" @click="checkAuthsOk(checkedKeys, roleId, 'auths')">
            保存
          </a-button>
        </div>
      </a-drawer>

      <create-form
        ref="createModal"
        :visible="visible"
        :loading="confirmLoading"
        :model="mdl"
        :edit="editModal"
        @cancel="handleCancel('add')"
        @ok="handleOk"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { roles, addRoles, patchRoles, getRolesAuths, putRolesAuths, delRoles, getRoles } from '@/api/roles'
import { auths, authTree } from '@/api/auths'
import { defaultAuth } from '@/common/const'

import CreateForm from './modules/CreateRoleForm'

const columns = [
  {
    title: '角色名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '角色ID',
    dataIndex: 'id'
  },
  {
    title: '描述',
    dataIndex: 'description'
  },
  {
    title: '权限',
    dataIndex: 'auth',
    scopedSlots: { customRender: 'auth' }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    sorter: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'Auth',
  components: {
    STable,
    Ellipsis,
    CreateForm
  },
  data() {
    this.columns = columns
    return {
      title: '查看权限',
      noticeTitle: '角色管理',
      totalCount: 0,
      visible: false,
      confirmLoading: false,
      mdl: null,
      editModal: false,
      // 删除 modal
      del: {
        ModalText: '您要删除这个角色吗？',
        visible: false,
        confirmLoading: false
      },
      // 查看权限
      roleId: null,
      fetchAuths: null,
      fetchAuthTree: [],
      byIdAuths: null,
      authVisible: false,
      authLoading: false,
      saveLoading: false,
      checkedKeys: [],
      replaceFields: { title: 'name', key: 'id' },
      loadData: parameter => {
        const param = this.paramFormat(parameter)
        return roles(param).then(res => {
          const data = res._embedded.roles
          const page = res.page
          const result = {
            data: data,
            pageNo: page.number,
            pageSize: page.size,
            totalCount: page.totalElements,
            totalPages: page.totalPages
          }
          this.totalCount = result.totalCount
          return result
        })
      },
      selectedRows: []
    }
  },
  watch: {},
  created() {},
  computed: {
    serial() {
      return this.$store.state.env.serial
    }
  },
  methods: {
    handle(record, type = 'add') {
      const form = this.$refs.createModal.form
      switch (type) {
        case 'add':
          form.resetFields()
          this.editModal = false
          this.mdl = null
          this.visible = true
          break
        case 'edit':
          this.visible = true
          this.getRoleInfo(record.id, data => {
            this.editModal = true
            this.mdl = { ...data }
          })
          break
        case 'del':
          this.mdl = { ...record }
          this.del.visible = true
          break
        case 'auth':
          this.lookAuths(record)
          break
        case 'user':
          this.lookUsers(record)
          break
      }
    },
    handleCancel(type) {
      switch (type) {
        case 'add':
          this.mdl = null
          this.visible = false
          break
        case 'edit':
          this.mdl = null
          this.visible = false
          break
        case 'auth':
          this.authVisible = false
          this.checkedKeys = []
          break
        case 'del':
          this.del.visible = false
          break
        default:
          this.visible = false
          this.authVisible = false
          break
      }
    },
    resetTable(handle, msg = null, type = 'success') {
      const form = this.$refs.createModal.form
      switch (handle) {
        case 'add':
          if (!msg) {
            msg = '添加成功。'
          }
          this.confirmLoading = false
          form.resetFields()
          this.visible = false
          this.saveLoading = false
          break
        case 'edit':
          if (!msg) {
            msg = '修改成功。'
          }
          this.confirmLoading = false
          form.resetFields()
          this.visible = false
          break
        case 'del':
          if (!msg) {
            msg = '删除成功。'
          }
          this.confirmLoading = false
          this.del.visible = false
          break
        case 'auths':
          if (!msg) {
            msg = '修改角色权限成功。'
          }
          this.checkedKeys = []
          this.authVisible = false
          this.saveLoading = false
          break
        default:
          this.checkedKeys = []
          this.visible = false
          this.authVisible = false
          break
      }
      this.$refs.table.refresh()
      this.$notification[type]({
        message: this.noticeTitle,
        description: msg
      })
    },
    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          const requestParameters = { ...values }
          if (this.mdl !== null) {
            patchRoles(requestParameters.id, requestParameters)
              .then(res => {
                setTimeout(() => {
                  this.resetTable('edit')
                }, 1000)
              })
              .catch(() => {
                this.confirmLoading = false
              })
          } else {
            addRoles(requestParameters)
              .then(res => {
                this.checkAuthsOk(defaultAuth, res.id, 'add')
              })
              .catch(() => {
                this.confirmLoading = true
              })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleDeleteOk(e) {
      this.del.ModalText = '将在两秒后删除此项。'
      this.del.confirmLoading = true
      setTimeout(() => {
        this.del.confirmLoading = false
        this.del.ModalText = '您要删除这个角色吗？'
        this.confirmLoading = true
        if (this.del.visible !== false) {
          const { mdl } = this
          delRoles(mdl.id)
            .then(res => {
              this.resetTable('del')
            })
            .catch(() => {
              this.del.confirmLoading = false
            })
        } else {
          this.del.visible = false
          this.confirmLoading = false
        }
      }, 2000)
    },
    getRoleInfo(id, cb) {
      getRoles(id).then(res => {
        console.log(res, 'role res')
        cb(res)
      })
    },
    /**
     * 权限管理
     */
    lookAuths(record) {
      const id = record.id
      this.authLoading = true
      this.authVisible = true
      this.roleId = id
      this.title = record.name
      this.getAllAuths(authsRes => {
        this.fetchAuths = authsRes
        this.getAuthTree(authTreeRes => {
          const treeData = authTreeRes
          this.fetchAuthTree = treeData
          this.getByIdAuths(id, byIdAuthRes => {
            this.byIdAuths = byIdAuthRes
            this.checkedKeys = byIdAuthRes.map(item => {
              return item.id
            })
          })
        })
      })
    },
    // api
    getAllAuths(cb) {
      auths().then(res => {
        cb(res._embedded.auths)
      })
    },
    getAuthTree(cb) {
      authTree().then(res => {
        cb(res.data)
      })
    },
    getByIdAuths(id, cb) {
      getRolesAuths(id).then(res => {
        this.authLoading = false
        cb(res._embedded.auths)
      })
    },
    // fn
    checkAuthsOk(auth = null, id = null, type = 'auths') {
      this.saveLoading = true
      const { checkedKeys, roleId } = this
      let newArr = []
      let newId = null
      if (auth) {
        newArr = auth
        newId = id
      } else {
        newArr = checkedKeys
        newId = roleId
      }
      const validateAuth = defaultAuth
      console.log(validateAuth)
      let validateArr = []
      if (newArr.length === 0) {
        validateArr = []
      } else {
        newArr.filter(item => {
          if (validateAuth.includes(item)) {
            console.log(item)
            validateArr.push(item)
          }
        })
      }
      if (validateArr.length === 0 || validateArr.length !== 2) {
        this.$notification.error({
          message: this.noticeTitle,
          description: '首页和驾驶舱为必选项，请选择后再保存'
        })
        this.saveLoading = false
        return
      }
      const content = newArr
        .map(item => {
          return this.serial + '/rest/auths/' + item
        })
        .join('\n')
      putRolesAuths(newId, content)
        .then(res => {
          this.resetTable(type)
        })
        .catch(() => {
          this.saveLoading = false
        })
    },
    /**
     * Tree
     *
     */
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys.checked
    },
    setTree(arr) {
      arr.forEach(item => {
        if (item.children !== undefined && item.children !== null) {
          if (item.children.length === 0) {
            delete item.children
          } else {
            this.setTree(item.children)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.myTree {
  /deep/ .ant-tree-treenode-switcher-close {
    margin: 15px 0 15px 0px;
  }
  /deep/ .ant-tree-child-tree .ant-tree-child-tree-open {
    li:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
