import request from '@/utils/request'

const authsApi = {
  // get auths info
  auths: '/api/v1/rest/auths',
  authTree: '/api/v1/auth/tree',
  authVerify: '/api/v1/auth/verify',
  findByOrderLogsId: '/api/v1/rest/auths/search/findByOrderId',
  deleteAllByPlatAuth: '/api/v1/rest/auths/search/deleteAllByPlat',
  findAllAuthAuth: '/api/v1/rest/auths/search/findAllIds',
  findByPlatAndParentIdOrderBySortAuth: '/api/v1/rest/auths/search/findByPlatAndParentIdOrderBySort',
  findByPlatAndParentIsNullOrderBySortAuth: '/api/v1/rest/auths/search/findByPlatAndParentIsNullOrderBySort'
}

export function auths(params) {
  let url = authsApi.auths
  if (params) {
    url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function getAuths(id) {
  return request({
    url: authsApi.auths + `/${id}`,
    method: 'get'
  })
}

export function addAuths(data) {
  return request({
    url: authsApi.auths,
    method: 'post',
    data
  })
}

export function putAuths(id, data) {
  return request({
    url: authsApi.auths + `/${id}`,
    method: 'put',
    data
  })
}

export function patchAuths(id, data) {
  return request({
    url: authsApi.auths + `/${id}`,
    method: 'patch',
    data
  })
}

export function delAuths(id) {
  return request({
    url: authsApi.auths + `/${id}`,
    method: 'delete'
  })
}

export function getAuthsChildren(id) {
  return request({
    url: authsApi.auths + `/${id}` + '/children',
    method: 'get'
  })
}

export function putAuthsChildren(id, data) {
  return request({
    url: authsApi.auths + `/${id}` + '/children',
    method: 'put',
    data
  })
}

export function postAuthsChildren(id, data) {
  return request({
    url: authsApi.auths + `/${id}` + '/children',
    method: 'post',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function patchAuthsChildren(id, data) {
  return request({
    url: authsApi.auths + `/${id}` + '/children',
    method: 'patch',
    data
  })
}

export function getAuthsIdChildren(id, authId) {
  return request({
    url: authsApi.auths + `/${id}` + '/children' + `/${authId}`,
    method: 'get'
  })
}

export function delAuthsIdChildren(id, authId) {
  return request({
    url: authsApi.auths + `/${id}` + '/children' + `/${authId}`,
    method: 'delete'
  })
}

export function delAuthsChildren(id) {
  return request({
    url: authsApi.auths + `/${id}` + '/children',
    method: 'delete'
  })
}

export function getAuthsParent(id, data) {
  return request({
    url: authsApi.auths + `/${id}` + '/parent',
    method: 'get',
    data
  })
}

export function putAuthsParent(id, data) {
  return request({
    url: authsApi.auths + `/${id}` + '/parent',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

export function patchAuthsParent(id, data) {
  return request({
    url: authsApi.auths + `/${id}` + '/parent',
    method: 'patch',
    data
  })
}

export function delAuthsParent(id) {
  return request({
    url: authsApi.auths + `/${id}` + '/parent',
    method: 'delete'
  })
}

export function authTree(params) {
  let url = authsApi.authTree
  if (params) {
    url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function authVerify(params = {}) {
  let url = authsApi.authVerify
  if (params) {
    url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function deleteAllByPlatAuth(params) {
  let url = authsApi.deleteAllByPlatAuth
  if (params) {
    url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findAllAuthAuth(params) {
  let url = authsApi.deleteAllByPlatAuth
  if (params) {
    url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findByPlatAndParentIdOrderBySortAuth(params) {
  let url = authsApi.deleteAllByPlatAuth
  if (params) {
    url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findByPlatAndParentIsNullOrderBySortAuth(params) {
  let url = authsApi.deleteAllByPlatAuth
  if (params) {
    url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}
